import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import duration from "dayjs/plugin/duration";
import _ from "underscore";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(duration);

// dayjs.updateLocale('en', {
//     formats: {
//         hoursMins: 'h:mm'
//     }
// })

export const convertToDropdownData = (data, returnObjectKey, dataKey, defaultCase = false) => {
    if (data) {
        return data.map(item => {
            let value = item[returnObjectKey];
            let description = item[returnObjectKey];

            if (typeof item[returnObjectKey] === 'string') {
                value = defaultCase ? value : value.toLowerCase();
            }

            if (_.isArray(returnObjectKey)) {
                description = '';
                returnObjectKey.forEach(key => {
                    description = `${description} ${item[key]}`;
                });

                description = description.trim()
            }

            return {
                id: item.id,
                [dataKey]: value,
                description: description
            }
        })
    }
}

export const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}

export const getDurationInMins = (date1, date2) => {
    if (date1 && date2) {
        return dayjs.duration(dayjs(date2).diff(dayjs(date1))).asMinutes();
    }

}

export const calculateHoursFromDuration = (time) => {
    if (time) {
        const hours = Math.floor(time / 60);

        return Number.isNaN(hours) ? '' : `${hours} hrs`;
    }

    return '';
}

export const calculateMinsFromDuration = (time) => {
    if (time) {
        const mins = Math.floor(time % 60);

        return Number.isNaN(mins) ? '' : `${mins} mins`;
    }

    return '';
}

export const dateDiffInDays = (date1, date2) => {
    const _MS_PER_DAY = 1000 * 60 * 60;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const formatDate = (date, format) => {
    if (date) {
        return dayjs(date).format(format);
    }

    return '';
}

export const parseJwt = token => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch(exception) {
        return null;
    }
}

export const buildRequestHeaders = () => {
    return {
        headers: {
            Authorization: `Bearer ${getJwtToken()}`,
            withCredentials: true
        }
    }
}

export const getJwtToken = () => {
    return localStorage.getItem("token");
}
