import Box from "@mui/material/Box";
import {Divider, Drawer, List, Typography} from "@mui/material";
import React from "react";
import {Roles, Styles} from "../contstants";
import {NavLink} from "react-router-dom";
import {
    AccountBalance,
    AccountCircle,
    AdminPanelSettings,
    AlignHorizontalLeft,
    Apartment,
    Assessment,
    Badge,
    Business,
    Event,
    Payments,
    PunchClock,
    Settings,
    SupervisedUserCircle,
    Title,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import {useSelector} from "react-redux";


const hrLinks = [
    {
        name: 'Employees',
        path: '/admin/dashboard/employees',
        icon: <Badge/>
    },
    {
        name: 'Employee Search',
        path: '/admin/dashboard/employee/search',
        icon: <Badge/>
    },
    {
        name: 'Profile',
        path: '/admin/dashboard/profile',
        icon: <AccountCircle/>
    },
]

const payrollLinks = [
    {
        name: 'Employees',
        path: '/admin/dashboard/employees',
        icon: <Badge/>
    },
    {
        name: 'Payroll',
        path: '/payroll/view',
        icon: <Assessment/>
    },
    {
        name: 'Profile',
        path: '/admin/dashboard/profile',
        icon: <AccountCircle/>
    },
]

const adminLinks = [
    {
        name: 'Employees',
        path: '/admin/dashboard/employees',
        icon: <Badge/>
    },
    {
        name: 'Employee Search',
        path: '/admin/dashboard/employees/search',
        icon: <PunchClock/>
    },
    {
        name: 'break',
    },
    {
        name: 'Companies',
        path: '/admin/dashboard/companies',
        icon: <Business/>
    },
    {
        name: 'Clusters',
        path: '/admin/dashboard/clusters',
        icon: <Apartment/>
    },
    {
        name: 'Posts',
        path: '/admin/dashboard/posts',
        icon: <SupervisedUserCircle/>
    },
    {
        name: 'break',
    },
    // {
    //     name: 'Payroll',
    //     path: '/payroll/view',
    //     icon: <Assessment/>
    // },
    {
        name: 'Payroll',
        path: '/payroll/view',
        icon: <Payments/>
    },
    {
        name: 'break',
    },
    {
        name: 'Profile',
        path: '/admin/dashboard/profile',
        icon: <AccountCircle/>
    },
    {
        name: 'break'
    },
    {
        name: 'Job Titles',
        path: '/admin/dashboard/job-titles',
        icon: <Title/>
    },
    {
        name: 'Holidays',
        path: '/admin/dashboard/holidays',
        icon: <Event/>
    },
    {
        name: 'App Settings',
        path: '/admin/settings',
        icon: <Settings/>
    },
    {
        name: 'Dashboard Users',
        path: '/admin/dashboard/users',
        icon: <AdminPanelSettings/>
    },
    {
        name: 'Banks',
        path: '/admin/dashboard/banks',
        icon: <AccountBalance/>
    },
    {
        name: 'Contribution Range',
        path: '/admin/dashboard/contribution-range',
        icon: <AlignHorizontalLeft/>
    },
    {
        name: 'break'
    },
    {
        name: 'Logout',
        path: '/login',
        icon: <LogoutIcon/>,
        onClick: () => RedirectLogout()
    }
]

const RedirectLogout = () => {
    localStorage.removeItem('token');
    return;
}

const linkClasses = {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 1)',
    "&:visited": {
        color: 'rgba(0, 0, 0, 1)',
    },
    "&:active": {
        color: 'rgba(0, 0, 0, 1)',
    }
}

function DrawerComponent() {
    let links = [];
    const user = useSelector(state => state.user);

    if (user && user.role) {
        if (user?.role?.name === Roles.ADMIN) {
            links = adminLinks;
        } else if (user?.role?.name === Roles.HR) {
            links = hrLinks;
        } else if (user?.role?.name === Roles.PAYROLL) {
            links = payrollLinks;
        }
    }

    return (
        <Drawer
            // container={container}
            variant="permanent"
            open={true}
            // onClose={handleDrawerToggle}
            sx={{
                display: {xs: 'flex'},
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: Styles.DRAWER_WIDTH,
                    marginTop: '100px'
                },
            }}
        >
            <>
                <List sx={{
                    marginLeft: '14px',
                    overflowY: 'scroll',
                    height: '80%',
                }}>
                    {
                        links.map((item, index) => {

                        if (item.name === 'break') {
                            return (
                                <Divider
                                    key={`navlink-${index}`}
                                    sx={{
                                        width: '95%',
                                        border: 'none',
                                        backgroundColor: 'rgba(0, 0, 0, .1)',
                                        height: '2px',
                                        margin: '4px 0',
                                    }}
                                />
                            )
                        }

                        return (
                            <NavLink
                                key={`navlink-${index}`}
                                to={item.path}
                                style={{...linkClasses}}
                                onClick={item?.onClick}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '60px',
                                        color: 'rgba(0, 0, 0, .8)'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            fontSize: '20px',
                                            '& svg': {fontSize: '26px'}
                                        }}
                                    >
                                        {item.icon}
                                    </Box>
                                    <Typography
                                        variant="span"
                                        sx={{
                                            minHeight: '20px',
                                            fontSize: '16px',
                                            marginLeft: '10px'
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                </Box>
                            </NavLink>
                        )
                    })}
                </List>
            </>
        </Drawer>
    );
}

export default DrawerComponent;