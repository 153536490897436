import React, {useCallback, useEffect, useState} from "react";
import _ from "underscore";
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {axiosInstance} from "../../config/interceptors";
import {setShowSnackbar} from "../../redux/action-creators/ui";
import {SnackbarMessages, SnackbarTypes} from "../../contstants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {buildRequestHeaders} from "../../utils";


const textFieldWidth = '300px';
const rowMargin = '14px 0 14px';
const headerMargins = '0 0 18px';
const labelMargin = '8px';
const labelFontWeight = '500';

const formDataInitialState = {
    regularHolidayMultiplier: 0,
    specialNonWorkingHolidayMultiplier: 0,
    notificationEmail: ''
}

const AppSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let defaultFormData = formDataInitialState;

    const [formData, setFormData] = useState(defaultFormData);
    const [formError, setFormError] = useState({});

    let snackbarMessage = {};

    // useEffect for mounting components
    // ran only once
    useEffect(() => {
        axiosInstance.get('/admin/app-settings', buildRequestHeaders())
            .then(response => {
                setFormData(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }, []);

    const submitOnClick = useCallback(() => {
        let params = {
            ...formData,
        }

        if (!validateForm(params)) {
            let url = '/admin/app-settings/save';

            axiosInstance.post(url, {
                ...params
            }, buildRequestHeaders()).then(response => {
                snackbarMessage = SnackbarMessages.success.save;
            }).catch(error => {
                snackbarMessage = SnackbarMessages.error.save;

                console.error(error);
            }).finally(() => {
                dispatch(setShowSnackbar(snackbarMessage))
                snackbarMessage = {};
            });
        } else {
            dispatch(setShowSnackbar({
                message: 'Please check all entries',
                title: 'Failed!',
                type: SnackbarTypes.ERROR,
            }))
        }
    }, [formData, formError])

    if (_.isEqual(formData, formDataInitialState)) {
        return false;
    }

    const validateForm = (params) => {
        let hasError = false;
        let errors = {}

        setFormError(errors);
        return hasError;
    }

    const cancelOnClick = () => {
        navigate("/admin/");
    }

    const setRegularHolidayMultiplier = value => {
        setFormData({
            ...formData,
            regularHolidayMultiplier: value,
        })
    }

    const setSpecialNonWorkingHolidayMultiplier = value => {
        setFormData({
            ...formData,
            specialNonWorkingHolidayMultiplier: value,
        })
    }

    const setNotificationEmail = value => {
        setFormData({
            ...formData,
            notificationEmail: value,
        })
    }

    const setNightDifferentialMultiplier = value => {
        setFormData({
            ...formData,
            nightDifferentialMultiplier: value,
        })
    }

    const setDaysAbsentNotice = value => {
        setFormData({
            ...formData,
            daysAbsentNotice: value,
        })
    }

    const setPfDeductionEmployer = value => {
        setFormData({
            ...formData,
            pfDeductionEmployer: value,
        })
    }

    const setPfDeductionEmployee = value => {
        setFormData({
            ...formData,
            pfDeductionEmployee: value,
        })
    }

    const setPhDeductionPercentage = value => {
        setFormData({
            ...formData,
            phDeductionPercentage: value,
        })
    }

    return (
        <Box sx={{
            height: '100%',
            width: '80%',
            marginLeft: 'calc(240px + 20px)',
            marginTop: '40px',
            paddingTop: '20px'
        }}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Payroll
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Regular Holiday Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['regularHolidayMultiplier']}
                                    onChange={event => setRegularHolidayMultiplier(event.target.value)}
                                    error={formError['regularHolidayMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Special Holiday Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['specialNonWorkingHolidayMultiplier']}
                                    onChange={event => setSpecialNonWorkingHolidayMultiplier(event.target.value)}
                                    error={formError['specialNonWorkingHolidayMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Night Differential Multiplier
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['nightDifferentialMultiplier']}
                                    onChange={event => setNightDifferentialMultiplier(event.target.value)}
                                    error={formError['nightDifferentialMultiplier']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Pag-ibig Fund Deduction (Employer)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['pfDeductionEmployer']}
                                    onChange={event => setPfDeductionEmployer(event.target.value)}
                                    error={formError['pfDeductionEmployer']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Pag-ibig Fund Deduction (Employee)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['pfDeductionEmployee']}
                                    onChange={event => setPfDeductionEmployee(event.target.value)}
                                    error={formError['pfDeductionEmployee']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Philhealth Deduction in Percengate (e.g. 0.04)
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['phDeductionPercentage']}
                                    onChange={event => setPhDeductionPercentage(event.target.value)}
                                    error={formError['phDeductionPercentage']}
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Attendance
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Days Absent Notice
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['daysAbsentNotice']}
                                    onChange={event => setDaysAbsentNotice(event.target.value)}
                                    error={formError['daysAbsentNotice']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    defaultExpanded={true}
                    sx={{}}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: "#FFFFFF"}}/>}
                        sx={{
                            borderBottom: `1px solid rgba(0,0,0,.14)`,
                            '&:before': {
                                display: 'none',
                            },
                            backgroundColor: 'rgba(67, 67, 67, .8)',
                            color: '#FFFFFF'
                        }}
                    >
                        <Typography

                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            Notifications
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                margin: rowMargin,
                            }}
                        >
                            <Box
                                sx={{
                                    marginRight: '14px',
                                    width: textFieldWidth
                                }}
                            >
                                <Typography className="inputFieldLabel"
                                            sx={{fontWeight: labelFontWeight}}>
                                    Email
                                </Typography>
                                <TextField
                                    sx={{width: '100%', marginTop: labelMargin}}
                                    variant="outlined"
                                    defaultValue={formData['notificationEmail']}
                                    onChange={event => setNotificationEmail(event.target.value)}
                                    error={formError['notificationEmail']}
                                    // helperText={getHelperText(formError['employeeId'])}
                                    size="small"
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    margin: rowMargin,
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        width: '175px',
                        marginRight: '14px'
                    }}
                    onClick={submitOnClick}
                >
                    <Typography
                        sx={{
                            padding: '8px',
                            fontSize: '16px',
                        }}
                    >Submit
                    </Typography>
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        width: '175px'
                    }}
                    onClick={cancelOnClick}
                >
                    <Typography
                        sx={{
                            padding: '8px',
                            fontSize: '16px'
                        }}
                    >Cancel
                    </Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default AppSettings;
