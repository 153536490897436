import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExport,
} from "@mui/x-data-grid";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import AlertDialog from "../../components/alert-dialog";
import {Constants, Styles} from "../../contstants";
import InputField from "../../components/input-field";
import Dropdown from "../../components/dropdown";
import DateField from "../../components/date-field";
import dayjs from "dayjs";

const EditToolbar = props => {
    const {dashboardName, handleAddButtonOnClick, overrides} = props;

    let  onClick = handleAddButtonOnClick;
    if (overrides.addButtonOnClick) {
        onClick = overrides.addButtonOnClick;
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon/>} onClick={onClick}>
                Add {dashboardName}
            </Button>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const DashboardComponent = ({
                                columns = [],
                                dashboardName,
                                deleteRowData,
                                dropdownData,
                                formError = {},
                                formFields = {},
                                getAllRowData,
                                getRowData,
                                formData,
                                formMode,
                                overrides = {},
                                rows = [],
                                saveFormData,
                                selectedRowData,
                                setFormData,
                                setFormError,
                                setFormMode,
                                setSelectedRowData,
                                updateRowData,
                            }) => {

    useEffect(() => {
        if (getAllRowData) {
            getAllRowData()
        }
    }, []);

    const [showOpenModal, setShowOpenModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState();

    const saveDialogTitle = formMode === Constants.EDIT_MODE ? `Update ${dashboardName}` : `New ${dashboardName}`;

    const initializeForm = () => {
        setFormData({});
        setSelectedRowData({});
        setFormMode(Constants.SAVE_MODE);
        setFormError({})
    }

    const setData = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        })
    }

    const findRowData = (id) => {
        const data = rows.filter((data) => data.id === id);
        if (data && data.length > 0) {
            return data[0];
        }
    }

    const handleEditClick = (id) => () => {
        const data = findRowData(id);

        if (overrides.viewButtonOnClick) {
            overrides.viewButtonOnClick(data);
        }

        setFormMode(Constants.EDIT_MODE);

        if (data) {
            setSelectedRowData(data);
            setFormData(data);

            handleAddButtonOnClick();
        } else {
            initializeForm();
            console.error("Unexpected error occurred. Cannot edit selected record.")
        }
    };

    const handleDeleteClick = (id) => () => {
        const role = findRowData(id);

        if (role) {
            setFormMode(Constants.DELETE_MODE);
            setSelectedRowData(role)
            handleOpenDeleteModal();
        } else {
            initializeForm();
            console.error("Unexpected error occurred. Cannot delete selected record.")
        }
    };

    const dashboardColumns = [
        ...columns,
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                        icon={<PersonSearchIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    const handleAddButtonOnClick = () => {
        setShowOpenModal(true);
    };

    const handleCloseModal = () => {
        setShowOpenModal(false);

        initializeForm();
    };

    const handleOpenDeleteModal = () => {
        setFormMode(Constants.DELETE_MODE);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDelete = () => {
        deleteRowData(() => {
            handleCloseDeleteModal();
        });
    }

    const cancelCallback = () => {
        handleCloseDeleteModal();
    }

    const saveCallback = () => {
        handleCloseModal();
    }

    const renderField = (field) => {
        if (field.fieldType === 'select') {
            return <Dropdown
                key={`dashboard-input-field-${field.name}`}
                fieldName={field.name}
                onChange={setData}
                label={field.label}
                isRequired={field.required}
                defaultValue={formData[field.name] && formData[field.name].id}
                error={formError[field.name]}
                dropdownData={dropdownData}
            />
        } else if (field.fieldType === 'text' || field.fieldType === null || field.fieldType === undefined) {
            return <InputField
                key={`dashboard-input-field-${field.name}`}
                fieldName={field.name}
                onChange={setData}
                label={field.label}
                isRequired={field.required}
                defaultValue={formData[field.name]}
                error={formError[field.name]}
            />
        } else if (field.fieldType === 'date') {
            return <DateField
                key={`dashboard-input-field-${field.name}`}
                fieldName={field.name}
                onChange={setData}
                label={field.label}
                isRequired={field.required}
                defaultValue={dayjs(formData[field.name])}
                error={formError[field.name]}
            />
        }
    }

    return (
        <Box sx={{
            height: `100%`,
            width: `calc(100% - ${Styles.DRAWER_WIDTH} - 40px)`,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '20px',
            marginLeft: `calc(${Styles.DRAWER_WIDTH} + 20px)`,
        }}>
            <Box
                sx={{display: 'flex', alignItems: 'flex-end', width: '100%'}}
            >
                <DataGrid
                    rows={rows}
                    columns={dashboardColumns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    autoHeight={true}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {handleAddButtonOnClick, dashboardName, overrides},
                    }}
                    disableRowSelectionOnClick
                />
            </Box>

            <Dialog open={showOpenModal}
                    onClose={handleCloseModal}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <DialogTitle>{saveDialogTitle}</DialogTitle>
                <DialogContent>
                    {
                        formFields.map(field => (
                            !field.ignore && renderField(field)
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => saveFormData(saveCallback)}>Submit</Button>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <AlertDialog
                isOpen={showDeleteModal}
                text="Are you sure you want to permanently delete these records?"
                header="Delete Records"
                confirmCallback={handleDelete}
                cancelCallback={handleCloseDeleteModal}
            />
        </Box>
    );
}

export default DashboardComponent;